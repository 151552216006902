import IngredientEmissionsInsight from '@app/pages/ingredients/components/ingredient-emissions-insights';
import { EmissionsCategoryEnum, OrganizationFoodList } from '@shared/api/types';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useDataTable } from '@shared/components/data-table/hooks/use-data-table';
import { useGetStaticFilterOptions } from '@shared/components/data-table/hooks/use-get-static-filter-options';
import { DataTableFilterField } from '@shared/components/data-table/types';

import RatingCloud, {
  RatingCloudProps,
} from '@shared/components/ratings/rating-cloud';
import { ColumnDef } from '@tanstack/react-table';
import { ClassValue } from 'clsx';

interface IngredientsTableProps {
  ingredients: OrganizationFoodList[];
  className?: ClassValue;
}

function getColumns(): ColumnDef<OrganizationFoodList>[] {
  return [
    {
      accessorKey: 'baseFoodUuid',
    },
    {
      accessorKey: 'longDesc',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Ingredient"
          className="text-gray-00"
        />
      ),
      cell: ({ getValue, row }) => {
        const name = getValue<string>();
        const infoMarkdown = row.original.infoMarkdown;
        const emissionsCategory = row.original.emissionsCategory;
        return (
          <p className="max-w-[240px] truncate">
            {name}
            {infoMarkdown && (
              <IngredientEmissionsInsight
                emissionsCategory={emissionsCategory}
                markdown={infoMarkdown}
                longDesc={name}
              />
            )}
          </p>
        );
      },
      enableHiding: false,
    },
    {
      accessorKey: 'emissionsCategory',
      header: ({ column }) => (
        <HeaderCell column={column} name="Rating" textWhite />
      ),
      cell: ({ getValue }) => {
        const rating = getValue<RatingCloudProps['rating']>();
        return (
          <RatingCloud
            rating={rating as RatingCloudProps['rating']}
            className={rating ? '' : 'w-[46px]'}
          />
        );
      },
      enableSorting: false,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
  ] as ColumnDef<OrganizationFoodList>[];
}

export default function IngredientsTable({
  ingredients,
}: IngredientsTableProps) {
  const { table } = useDataTable({
    data: ingredients,
    columns: getColumns(),
    columnVisibilityState: {
      baseFoodUuid: false,
    },
  });

  const filterFields: DataTableFilterField<OrganizationFoodList>[] = [
    {
      label: 'Ingredient',
      value: 'longDesc',
      placeholder: 'Search ingredient...',
    },
    {
      label: 'Rating',
      value: 'emissionsCategory',
      options: useGetStaticFilterOptions(EmissionsCategoryEnum),
    },
  ];

  return (
    <DataTable
      table={table}
      enablePagination
      className="bg-gray-00"
      headerConfig={{
        className: 'bg-primary',
      }}
    >
      <DataTableToolbar table={table} filterFields={filterFields} />
    </DataTable>
  );
}
