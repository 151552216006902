import IngredientEmissionsInsight from '@app/pages/ingredients/components/ingredient-emissions-insights';
import {
  EmissionsCategoryEnum,
  IngredientInsightsList,
} from '@shared/api/types';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { NumberCell } from '@shared/components/data-table/cells/number-cell';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useDataTable } from '@shared/components/data-table/hooks/use-data-table';
import { useGetStaticFilterOptions } from '@shared/components/data-table/hooks/use-get-static-filter-options';
import { DataTableFilterField } from '@shared/components/data-table/types';
import RatingCloud, {
  RatingCloudProps,
} from '@shared/components/ratings/rating-cloud';

import { cn } from '@shared/lib/utils';
import { ColumnDef } from '@tanstack/react-table';
import { ClassValue } from 'clsx';

interface IngredientsTableProps {
  ingredientsWithInsights: IngredientInsightsList[];
  className?: ClassValue;
}

function getColumns(): ColumnDef<IngredientInsightsList>[] {
  return [
    {
      accessorKey: 'uuid',
    },
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Ingredient"
          className="text-gray-00"
        />
      ),
      cell: ({ getValue, row }) => {
        const name = getValue<string>();
        const infoMarkdown = row.original.infoMarkdown;
        const emissionsCategory = row.original.emissionsCategory;
        return (
          <p className="max-w-[240px] truncate">
            {name}
            {infoMarkdown && (
              <IngredientEmissionsInsight
                emissionsCategory={emissionsCategory}
                markdown={infoMarkdown}
                longDesc={name}
              />
            )}
          </p>
        );
      },
      enableHiding: false,
    },
    {
      accessorKey: 'totalEmissionsPerKg',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Carbon Intensity"
          unit={
            <span>
              (kgCO<sub>2</sub>e/kg<sub>food</sub>)
            </span>
          }
          textWhite
        />
      ),
      cell: ({ getValue }) => {
        const totalEmissions: number = getValue<number>();
        return (
          (totalEmissions && (
            <NumberCell className="w-[100px]" number={totalEmissions} rounded />
          )) ??
          null
        );
      },
    },
    {
      accessorKey: 'emissionsCategory',
      header: ({ column }) => (
        <HeaderCell column={column} name="Rating" textWhite />
      ),
      cell: ({ getValue }) => {
        const rating = getValue<RatingCloudProps['rating']>();
        return (
          <RatingCloud
            rating={rating as RatingCloudProps['rating']}
            className={rating ? '' : 'w-[46px]'}
          />
        );
      },
      enableSorting: false,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
  ] as ColumnDef<IngredientInsightsList>[];
}

export default function IngredientsWithInsightsTable({
  ingredientsWithInsights,
  className,
}: IngredientsTableProps) {
  const filterFields: DataTableFilterField<IngredientInsightsList>[] = [
    {
      label: 'Ingredient',
      value: 'name',
      placeholder: 'Search ingredient...',
    },

    {
      label: 'Rating',
      value: 'emissionsCategory',
      options: useGetStaticFilterOptions(EmissionsCategoryEnum),
    },
  ];

  const { table } = useDataTable({
    data: ingredientsWithInsights,
    columns: getColumns(),
    filterFields,
    columnVisibilityState: {
      uuid: false,
    },
  });

  return (
    <div className={cn('', className)}>
      <DataTable
        table={table}
        enablePagination
        className="bg-gray-00"
        headerConfig={{
          className: 'bg-primary',
        }}
      >
        <DataTableToolbar table={table} filterFields={filterFields} />
      </DataTable>
    </div>
  );
}
